.whylisten-container {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem;
    align-items: center;
    background-color: white;
    border-radius: 8px;
}

.content-section {
    flex: 1;
    line-height: 1.8;
    max-width: 800px;
    text-align: center;
    margin-left: 2rem;
    margin-right: 2rem;
}

.whylisten-primary-text {
    margin-bottom: 1.5rem;
    color: var(--neutral-dark-text);
    font-weight: 545;
}
