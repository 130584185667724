/* =========================
   Variables
========================= */
:root {
    /* Base */
    --font-base-size: 1rem;

    /* Primary Palette */
    --primary-accent: #153c72; 
    --primary-accent-hover: #4d678b; 
    --primary-text-on-accent: #e3e8ee;

    /* Secondary Palette */
    --secondary-accent: #323334;
    --secondary-accent-hover: #6e2d5f; 
    --secondary-text-on-accent: #e3e8ee;

    /* Neutral Palette */
    --neutral-light: #9fb7d1; 
    --neutral-background: #e3e8ee;
    /* For dark text on light backgrounds */
    --neutral-dark-text: #323334;
    /* For light text on dark backgrounds */
    --neutral-light-text: #e3e8ee;

    /* Feedback Palette */
    --feedback-success: #28a745;
    --feedback-error: #dc3545;
    --feedback-warning: #ffc107;
    --feedback-info: #17a2b8;

    /* Accent Palette */
    --accent-highlight: #9c27b0;
}


/* =========================
   Reset and General settings
========================= */
body, html {
    margin: 0;
    padding: 0;
    font-size: var(--base-font-size);
    background: white;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans', sans-serif;
    color: var(--secondary-accent);
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1;
}

.fade-in-top {
    position: relative;
}

.fade-in-top::before {
    content: "";
    display: block;
    position: absolute;
    top: 10;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(to bottom, var(--primary-text-on-accent), transparent);
    pointer-events: none; /* Ensures this doesn't interfere with any clicks */
    z-index: 1;
}


/* =========================
   Typography
========================= */

.product-name {
    font-weight: bold;
    color: var(--secondary-accent-hover);
}

/* Primary Level */
h1, .title {
    font-size: 2rem; /* Larger screens */
    font-weight: bold;
    color: var(--neutral-dark-text)
}

/* Secondary Level */
h2, .subtitle {
    font-size: 1.5rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    position: relative;
    color: var(--neutral-dark-text);

}

h2::before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 2px;
    background: var(--primary-accent);
    transition: width 0.3s;
}

h2:hover::before {
    width: 100%;
}

/* Tertiary Level */
h3, .primary-text {
    font-size: 1.25rem;
}

/* Additional Levels */
h4, .secondary-text {
    font-size: 0.9rem;
    font-weight: normal;
}

/* Responsive adjustments for screens below 768px */
@media screen and (max-width: 768px) {
    h1, .main-title {
        font-size: 1.75rem;
    }

    h2, .subtitle {
        font-size: 1.3rem;
    }

    h3, .primary-text {
        font-size: 1rem;
    }

    h4, .secondary-text {
        font-size: 0.85rem;
    
    }
}


/* =========================
   Buttons
========================= */
.button-primary,
.button-secondary,
.button-large {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary-text-on-accent);
    background-color: var(--primary-accent);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-primary:hover,
.button-secondary:hover,
.button-large:hover {
    background-color: var(--primary-accent-hover);
    transform: translateY(-3px);
}

.button-secondary {
    background-color: transparent;
    border: 2px solid var(--primary-accent);
    color: var(--neutral-dark-text);
}


.cta-section {
    display: flex;
    justify-content: center; 
    align-items: center; 
    margin-top: 4rem; 
    margin-bottom: 4rem;
}



/* =========================
   Utility Classes
========================= */
.divider {
    width: 80%;
    height: 2px;
    background-color: var(--primary-accent-hover);
    margin: 2rem 0;
    opacity: 0.7;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-max-width {
    max-width: 1440px;
    margin: 0 auto;
    padding: 1rem 2rem;
}

@media screen and (min-width: 1440px) {
    .container-max-width {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media screen and (max-width: 1440px) {
    .container-max-width {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .title {
        font-size: 2rem;
    }
    
    .subtitle {
        font-size: 1.1rem;
    }    
}

@media screen and (max-width: 768px) {
    .container-max-width {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .title {
        font-size: 2rem;
    }
    
    .subtitle {
        font-size: 1rem;
    }   
}
