:root {
    --feature-padding-normal: 20rem;
    --feature-padding-offset: 20rem;
}

.feature {
    min-height: 40vh;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: all 0.3s ease-in-out; 
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); 
    padding-top: 2rem;  
    padding-bottom: 2rem;
}

.feature-content {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Align items in the center */
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    width: 95%;
    padding: 0 1rem;
}

.feature-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.feature:hover {
    transform: scale(1.02); 
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2); 
}

.feature:nth-child(odd) .feature-text {
    order: 2;
    padding-left: 3rem;
    padding-right: 0rem;
}

.feature:nth-child(even) {
    background: var(--primary-accent-hover);
}

.feature:nth-child(even) .feature-content,
.feature:nth-child(even) h1 {
    color: white;
}

.feature:nth-child(odd) {
    background: linear-gradient(var(--background-color), white);
}

.feature:nth-child(odd) .feature-content,
.feature:nth-child(odd) h1 {
    color: var(--neutral-dark-text);
}

.feature-image-placeholder {
    height: 400px;
    width: 100%;
    background-color: var(--background-color);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px; 
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); 
}

.feature-image {
    max-width: 100%; /* Full width of its container */
    max-height: 100%; /* Full width of its container */
    height: auto; /* Auto height to maintain aspect ratio */
}

.feature-content {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Add this line to center-align children vertically */
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    width: 95%;
    padding: 0 1rem;
}

.feature-text {
    flex: 1;
    display: flex; /* Make feature-text a flex container */
    flex-direction: column;
    justify-content: center; /* Center-align its children vertically */
    padding-right: 2rem;
}

.feature-visual {
    flex: 1;
    display: flex;
    align-items: center; /* Already centering image vertically */
    justify-content: center;
}

.feature:nth-child(odd) .feature-row {
    flex-direction: row-reverse;
}

/* For even features, image on the left */
.feature:nth-child(even) .feature-row {
    flex-direction: row;
}



@media (max-width: 768px) {
    /* Media queries for smaller screens */
    .feature-content {
        flex-direction: column;
    }

    .feature:nth-child(odd) .feature-row {
        flex-direction: column;
    }
    
    .feature:nth-child(even) .feature-row {
        flex-direction: column;
    }

    .feature-text, .feature-visual {
        flex-basis: unset; /* Reset flex-basis for smaller screens */
    }

    .feature-title h1 {
        text-align: center;
    }

    .feature-text {
        padding-top: 2rem; /* Add spacing between the image and text */
        flex: 1;
    }

    .feature-visual {
        flex: 1;
    }    

    .feature:nth-child(odd) .feature-text {
        padding-left: 0;
        padding-top: 2rem;
        order: unset
        
    }   
}
