.header-container {
    display: flex;
    justify-content: center;
    padding: 1.5rem 0; /* Increased padding for a more spacious look */
    background-color: var(--primary-text-on-accent);
    /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08); Slightly more shadow for a subtle elevated look */
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    width: 90%; /* Reduced width for better padding on the sides in larger screens */
    padding: 0 2rem;
}

.left-side, .right-side {
    display: flex;
    align-items: center;
}

.header-content, .left-side, .right-side {
    flex-wrap: wrap;
}

.header-logo {
    margin-right: 3rem;
}

.header-nav .nav-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.header-nav .nav-item:not(:first-child) {
    margin-left: 3rem;
}

.header-nav .nav-item {
    margin-right: 3rem;
}

.header-nav .nav-link {
    text-decoration: none;
    color: var(--secondary-accent);
    transition: color 0.3s ease;
    font-weight: 500; /* Giving a medium weight for better emphasis without being too bold */
}

.header-nav .nav-link:hover {
    color: var(--neutral-light);
}

.hamburger {
    background: none;
    color: var(--secondary-accent);
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    outline: none;
    display: none;
}

@media screen and (max-width: 950px) {
    .header-content {
        /* Retain default flex direction (row) */
    }

    .left-side, .right-side {
        flex: 1; /* Equal width for both left and right side */
    }

    .right-side {
        justify-content: flex-end; /* Push hamburger to the right */
    }

    .header-logo {
        margin-right: 1rem; /* Adjusted for smaller screens */
    }

    .header-nav .nav-list {
        flex-direction: column;
        margin-top: 1rem;
    }

    .header-nav .nav-item:not(:first-child) {
        margin-left: 0;
        margin-right: 0;
        margin-top: 1rem;
    }

    .hamburger {
        display: block;
    }

    .header-nav {
        display: none;
    }

    .header-nav.nav-open {
        display: flex; /* Show navigation when nav is open */
    }
}

