.background-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
}

.home-container {
    line-height: 1.6;
    overflow-x: hidden;
}

.top-section {
    align-items: center;
    background: transparent;
    transition: all 0.3s ease-in-out;
    min-height: 40vh;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    width: 90%; /* Reduced width for better padding on the sides in larger screens */
  
}

.top-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: var(--primary-accent);
}

.succeed-text {
    color: var(--secondary-accent-hover);
}

.cta-buttons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.image-placeholder {
    position: relative;
    flex: 1;
    max-width: 690px;
    align-self: center;
    border-radius: 10px;
    margin-left: 2rem;
    overflow: hidden; /* Hide anything that might overflow the bounds */
    width: 100%; /* Ensure it takes up the full width of its container */
}

.image-placeholder img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto; /* Adjust width to auto */
    height: 100%; /* Maintain full height */
    transform: translate(-50%, -50%); /* Center the image */
    object-fit: contain; /* Change to contain to prevent distortion */
}


.image-placeholder::before {
    content: "";
    display: block;
    padding-bottom: 56.25%; /* 9 / 16 = 0.5625 or 56.25% for a 16:9 aspect ratio */
}




.introductory-section {
    text-align: center;
    background-color: var(--secondary-accent-hover);
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 8px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
 
}

.introductory-section h2 {
    margin-bottom: 0rem;
    margin-top: 0rem;
    text-align: center;
    color: var(--neutral-light-text);
    text-shadow: none;
}

.introductory-section h2::before {
    background: var(--neutral-light-text);
}

.introductory-section p {
    color: var(--neutral-light-text);
    margin: 0 auto;    
}

:root {
    --feature-padding-normal: 2rem;
    --feature-padding-offset: 4rem;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .top-section {
        flex-direction: column-reverse;
        
    }

    .top-text, .image-placeholder {
        text-align: center;
        align-items: center;
    }

    .top-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 0rem;
        padding-left: 0rem;
        align-items: flex-start; 
    }

    .top-text h1 {
        margin: 0; 
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .cta-buttons {
        margin-top: 2rem;
    }
    
    .image-placeholder {
        position: relative;
        flex: 1;
        max-width: 320px;
        align-self: center;
        border-radius: 10px;
        margin-left: 2rem;
        overflow: hidden; /* Hide anything that might overflow the bounds */
    }

    .cta-buttons {
        justify-content: center;
        width: 100%;
    }

    .introductory-section {
        max-width: 330px;
        margin-left: auto;
        margin-right: auto;
         
    }
        
}
