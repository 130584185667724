/* Main container for the Pricing page */
.pricing-container {
    line-height: 1.6;
    overflow-x: hidden;
    padding: 2rem 5rem;
}

.pricing-header {
    margin-bottom: 2rem;
    text-align: center;
}

.pricing-cards {
    display: flex;
    justify-content: space-between;
    gap: 3rem; 
}

.pricing-card {
    background: #e3e8ee;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 2rem; 
    flex: 1; 
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative; /* added this to position child elements */
}

.pricing-title, .pricing-amount {
    font-weight: bold;
    margin: 1rem 0;
    text-align: center;
    color: var(--neutral-dark-text);
}

.pricing-features {
    list-style-type: none;
    padding: 0;
    margin-top: 2rem;
    max-width: 80%; /* Control the width of the features list to be 80% of the card width */
    text-align: center;
}

.pricing-features li {
    margin-bottom: 1rem;
}


.pricing-button {
    margin-top: auto;
}

@media (max-width: 768px) {
    .pricing-cards {
        flex-direction: column; /* Stack cards vertically on smaller screens */
        align-items: center; /* Center align the cards */
    }

    /* Targeting the Standard and Pro plan cards to hide them */
    .pricing-cards .pricing-card:nth-child(2),
    .pricing-cards .pricing-card:nth-child(3) {
        display: none;
    }
}