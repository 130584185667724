.register-container {
    line-height: 1.6;
    padding: 2rem;
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;
}

.register-title {
    margin-bottom: 1rem;
    font-weight: bold;
}

.register-subtitle {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    color: var(--primary-accent-hover)
}

.register-form {
    width: 500px;
    height: 200px; 

}

@media (max-width: 768px) {
    .register-form {
        width: 600px;
        height: 240px; 
    
    }
}