/* Footer.css */

* {
    box-sizing: border-box;
}

.footer-container {
    width: 100%;
    background: var(--primary-accent);
    color: var(--neutral-background);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0; /* Added padding for better vertical spacing */
}

.footer-links {
    display: flex;
    justify-content: flex-start;  /* Aligned to the start (left side) */
    width: 80%;
    gap: 2rem; /* Gap between link groups */
}

.link-group {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Increased the gap for better spacing */
}

.footer-links a {
    color: var(--neutral-light);
    text-decoration: none;
    transition: color 0.3s;
}

.footer-links a:hover {
    color: var(--primary-accent-hover);
}

.primary-link {
    font-weight: bold;
    color: var(--neutral-background); /* To make it stand out more */
}

.footer-top {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem; /* Added margin for separation from the links */
}

.footer-motto {
    color: var(--neutral-light);
    font-weight: bold; 
}

.footer-copyright {
    color: var(--neutral-light);
}

/* Footer.css */
.footer-motto .logo-full,
.footer-motto .logo-pauseListen {
  display: none;
}

.copyright-full,
.copyright-small {
  display: none;
}

/* For larger screens */
@media screen and (min-width: 768px) {
  .footer-motto .logo-full {
    display: block;
  }

  .copyright-full {
    display: block;
  }
}

/* For smaller screens */
@media screen and (max-width: 767px) {
  .footer-motto .logo-pauseListen {
    display: block;
  }

  .copyright-small {
    display: block;
  }  
}

