.logo-text {
    font-weight: bold;
    text-decoration: none;
    letter-spacing: -0.5px;
    display: flex;
    align-items: baseline;
    font-size: 1.2rem;
}

.logo-period {
    color: var(--neutral-light); 
    padding-right: 0.5rem;
}

.logo-part-one, .logo-part-three {
    color: var(--secondary-accent);
}

.logo-part-two {
    color: var(--secondary-accent-hover);
}

.footer-motto .logo-period {
    color: var(--neutral-light);
}

.footer-motto .logo-part-one {
    color: var(--neutral-light);
}

.footer-motto .logo-part-two {
    color: var(--neutral-light);
}

.footer-motto .logo-part-three {
    color: var(--neutral-light);
}

@media screen and (max-width: 768px) {
    .logo-part-one, .logo-part-two, .logo-part-three {
        font-size: 1rem;
    }
}
